<template>
  <router-link to="#" @click.native="selectedStudent()">
    <div :id="`div-${student.id}`" class="item media">
      <div class="media-left">
        <fa-icon
          :icon="[iconType, iconName]"
          class="fa-2x"
          style="padding-top: 6px; padding-left: 3px"
          :color="iconColor"
        />
      </div>
      <div class="media-content">
        <p class="title is-6">{{ student.name }}</p>
        <p class="subtitle is-text-8">
          <slot>
            Average:
            {{
              student.ateMarksAverageScore
                ? student.ateMarksAverageScore.average
                : 0
            }}
          </slot>
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    student: Object,
    iconType: String,
    iconName: String,
    iconColor: String,
  },
  methods: {
    selectedStudent() {
      const items = document.getElementsByClassName('item')

      for (var i = 0; i < items.length; i++) {
        items[i].classList.remove('active')
      }

      document
        .getElementById(`div-${this.student.id}`)
        .classList.toggle('active')

      this.$emit('set-student', this.student)
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  margin-top: 8px;
  padding: 10px;
}

.item:hover {
  // border: 1px solid #c0c0c0c0;
  background-color: rgb(185, 185, 185);
  opacity: 0.8;
}

.active {
  background-color: $primary;
  opacity: 0.8;
}
</style>
