export function toCurrency(value, symbol = null) {
  const money = `${value}`

  let { bigPart, smallPart } = checkMoney(money)

  const moneyLength = bigPart.length

  if (moneyLength < 4) return formatted(money, smallPart, symbol)

  const boundaries = Math.floor(moneyLength / 3)

  const counter = moneyLength % 3 === 0 ? boundaries - 1 : boundaries

  let commaIndex = 0

  for (let index = 0; index < counter; index++) {
    if (index === 0) {
      commaIndex = moneyLength % 3 === 0 ? 3 : moneyLength % 3
    } else {
      commaIndex = commaIndex + 4
    }
    bigPart = format(bigPart, commaIndex)
  }

  return formatted(bigPart, smallPart, symbol)
}

export function format(bigPart, commaIndex) {
  return (
    bigPart.slice(0, commaIndex) +
    ',' +
    bigPart.slice(commaIndex, bigPart.length)
  )
}

export function formatted(bigPart, smallPart, symbol) {
  const formatted = smallPart ? `${bigPart}.${smallPart}` : `${bigPart}.00`

  return symbol ? `${symbol} ${formatted}` : formatted
}

export function checkMoney(value) {
  // Check if it has dots.
  // If it has more than one reject it and throw an error.
  if (value.split('.').length > 2) {
    throw 'Check the number. Contains more than one dot.'
  } else {
    let [bigPart, smallPart] = value.split('.')
    smallPart = smallPart && smallPart.length == 1 ? `${smallPart}0` : smallPart
    return { smallPart: smallPart, bigPart: bigPart }
  }
}
