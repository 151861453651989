<template>
  <div>
    <section>
      <p class="subtitle is-6">Students In Class</p>
      <b-select placeholder="Select a class" v-model="schoolClass" expanded>
        <option v-for="schoolClass in schoolClasses" :key="schoolClass.id">
          {{ schoolClass.name }}
        </option>
      </b-select>
    </section>
    <section id="student-list" class="has-background-white-ter">
      <template v-if="$apollo.loading">
        <loading />
      </template>
      <template v-else>
        <div v-for="student in students" :key="student.id">
          <slot :student="student">{{ student.name }}</slot>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import SCHOOL_CLASSES from '../../graphql/school_class/SchoolClasses.gql'
import ASSIGNED_SCHOOL_CLASSES from '../../graphql/school_class/AssignedSchoolClasses.gql'

export default {
  data() {
    return {
      students: [],
      schoolClass: {},
      schoolClasses: [],
    }
  },
  props: {
    iconType: String,
    iconName: String,
    query: String,
    reload: { type: Boolean, default: false },
  },
  watch: {
    students(data) {
      this.$emit('reloaded', data)
    },
    schoolClass(value) {
      if (!value) return
      if (this.schoolClasses.length == 0) return

      this.schoolClassId = this.schoolClasses.filter(
        (schoolClass) => schoolClass.name == value
      )[0].id

      this.fetchStudents()
    },
    reload(value) {
      if (value) {
        this.fetchStudents()
      }
    },
  },
  methods: {
    fetchStudents() {
      this.students = []

      this.$apollo.addSmartQuery('students', {
        query: gql`
          ${this.query}
        `,
        variables: {
          schoolClassId: parseInt(this.schoolClassId),
        },
        fetchPolicy: 'network-only',
        update: (data) => data.studentsInClass,
      })

      this.$apollo.queries.students.refetch()
    },
    fetchSchoolClasses() {
      if (this.user.role === 'host' || this.user.role === 'admin') {
        this.$apollo
          .query({
            query: SCHOOL_CLASSES,
            variables: {
              schoolId: this.schoolId,
            },
          })
          .then((response) => {
            this.schoolClasses = response.data.schoolClasses
          })
          .catch((error) => {
            // console.error(error);
          })
      } else if (this.user.role === 'educator') {
        this.$apollo
          .query({
            query: ASSIGNED_SCHOOL_CLASSES,
            variables: {
              id: this.user.id,
            },
          })
          .then((response) => {
            this.schoolClasses =
              response.data.assignedSchoolClasses.schoolClasses
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  mounted() {
    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id
      this.fetchSchoolClasses()
    })
  },
}
</script>

<style lang="scss" scoped>
#student-list {
  height: 600px;
  padding: 10px;
  overflow-y: auto;
}
</style>
